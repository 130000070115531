import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/layouts/main/Layout';

export default class TermsPage extends React.Component<any> {
  render() {
    return (
      <Layout location={this.props.path}>
        <div className="policy-terms-body">
          <h1>CLICK SPRAY TERMS OF SERVICE</h1>
          <p>Last Changes to Website Terms of Service: February 20, 2020</p>
          <p>
            These Terms of Service (“<b>Terms of Service</b>”) constitute a legally binding
            agreement made between you, whether personally or on behalf of an entity (“<b>you</b>”)
            and <b>VB Brands, LLC </b>
            (collectively, “<b>VB Brands</b>” or “<b>we</b>” or “<b>us</b>” or “<b>our</b>”),
            governing your access to and use of the www.clickspray.com website as well as any other
            media form, media channel, mobile application or mobile website related or connected
            thereto (collectively, the “<b>Site</b>”), and services supplied by or on behalf of us
            through the Site (“<b>Services</b>”). Supplemental terms and conditions or documents
            that may be posted on the Site from time to time, are hereby expressly incorporated into
            these Terms by reference.
          </p>
          <p>
            Please read these Terms of Service carefully before accessing or using our Site. By
            accessing, browsing, or otherwise using the Site, you engage in our Services and agree
            to be bound by the following terms and conditions, including those additional terms and
            conditions and policies referenced or linked herein. These Terms of Service apply to all
            users of the Site, including without limitation users who are browsers, vendors,
            customers, merchants, and/or contributors of content. If you do not agree to all the
            terms and conditions contained herein, then you may not access the Site or use any
            Services. If these Terms of Service are considered an offer, acceptance is expressly
            limited to these Terms of Service.
          </p>
          <p>
            Any new features, content, or tools which are added to the current Site shall also be
            subject to the Terms of Service. You can review the most current version of the Terms of
            Service at any time on this page. We reserve the right to update, change or replace any
            part of these Terms of Service in our sole discretion by posting updates and/or changes
            to our Site. It is your responsibility to check this page periodically for changes. Your
            continued use of or access to the Site following the posting of any changes constitutes
            acceptance of those changes.
          </p>
          <p>
            Information provided on the Site is not intended for distribution to or use by any
            person or entity in any jurisdiction or country where such distribution or use would be
            contrary to law or regulation or which would subject us to any registration requirement
            within such jurisdiction or country. Persons who choose to access the Site from such
            locations do so on their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </p>
          <p>
            USERS WITH QUESTIONS REGARDING THE LEGALITY OF CANNABIS AGREE THEY HAVE CONTACTED
            INDEPENDENT LEGAL COUNSEL OF THEIR CHOOSING OR HAVE KNOWINGLY CHOSEN TO WAIVE THAT RIGHT
            BEFORE USING THIS SITE OR SERVICES.
          </p>
          <p>
            YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS BY CONTINUING TO USE THE SITE. IF YOU DO
            NOT AGREE TO ABIDE BY THESE TERMS, OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THESE
            TERMS IN THE FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE SERVICES OR
            THE SITE.
          </p>
          <h2>SECTION 1 – SAFETY ACKNOWLEDGMENT</h2>
          <p>
            On our Site, we provide information about our products but do not offer any products for
            sale. Our Site may collect information about a user’s experiences and preferences
            related to the consumption of cannabis or cannabis products. You acknowledge that, as it
            pertains to cannabis, Our products are only for residents of California. Cannabis is a
            Schedule I controlled substance under the Controlled Substances Act, and, therefore, the
            possession, cultivation and distribution thereof, or conspiring with or assisting others
            to do the same, is federally illegal and can result in significant criminal and civil
            penalties. You further acknowledge that medical use is not recognized as a valid defense
            under federal laws regulating cannabis, and that the interstate transportation of
            cannabis is a federal offense. ENGAGING IN ACTIVITIES OR BUSINESS RELATED TO CANNABIS IS
            AT YOUR OWN RISK.
          </p>
          <p>
            By using this Site, you acknowledge the information contained in these Terms of Service,
            the information provided on this Site, within any of Company’s social media pages or
            channels, and all of the documentation and literature included with any product
            identified on the Site, was developed for informational and educational purposes only.
            In no way is any of the information contained in these Terms of Service or elsewhere on
            the Site intended to be a medical or prescriptive guide or a substitute for informed
            medical advice or care, nor is it intended to cover all possible uses, directions,
            precautions, or adverse effects. You should never delay seeking or disregard advice from
            a medical professional based on something you have read on the Site. Do not operate
            vehicles or machinery while under the influence of cannabis. If you are pregnant or may
            be pregnant, breastfeeding, suffering from a known health condition, or taking any
            prescription medications, you should consult a physician or other health care
            professional prior to using cannabis. You and any other user of any product on the Site
            are solely responsible for the use of such product and the consequences of such use. Any
            illegal use or resale of any products listed on the Site could subject you to fines,
            penalties and/or imprisonment under state and federal law.
          </p>
          <h2>SECTION 2 – REGISTRATION AND USER INFORMATION</h2>
          <p>
            In order to register to use certain features of the Site you must complete the
            registration process to obtain a user account (“
            <b>User Account</b>”). In order to obtain a User Account, you must provide your age,
            your e-mail address, and a username and password. You will then be given the option to
            create a profile, in response to which, you may, but are not required to, provide your
            gender, date of birth, and a profile picture (“<b>User Information</b>”). You must
            provide complete and accurate information during the registration process and you have
            an ongoing obligation to update this information if and when it changes. Our information
            collection and use policies with respect to your User Information are set in our, &nbsp;
            <Link style={{ textDecoration: 'underline' }} to="/privacy">
              Privacy Policy
            </Link>
            &nbsp; which is incorporated into these Terms of Service by reference. An age
            verification check will occur during the registration process in order to confirm that
            you are 21 years of age or older. Only persons 21 years of age or older will be granted
            access to the Site.
          </p>
          <p>
            As stated above, in creating a User Account, you will be asked to provide a username and
            password. You are solely responsible for maintaining the confidentiality of your
            password. You may not use the account, username, or password of someone else at any
            time. You are also solely responsible for any and all activities that occur under your
            registration or your User Information. You agree to notify us immediately of any
            unauthorized use of your account, username, or password.
          </p>
          <p>
            You agree that you will not create more than one User Account. By registering and
            obtaining an account you affirm you will follow the Terms of Service and your
            registration constitutes your consent to enter into agreements with us electronically.
          </p>
          <p>
            We shall not be liable for any loss that you incur as a result of someone else using
            your User Account, username, or password, either with or without your knowledge. You may
            be held liable for any losses incurred by us, our affiliates, officers, directors,
            employees, consultants, agents, and representatives due to someone else’s use of your
            User Account, username, or password.
          </p>
          <p>
            We reserve the right to terminate your registration or to refuse services to you,
            without prior notice to you, at any time and for any or no reason.
          </p>
          <h2>SECTION 3 – ELIGIBILITY</h2>
          <p>
            You must be 21 years of age to use the Site and/or Services related to the Site. You
            must also be legally eligible to use the Site and/or Services pursuant to the laws of
            the State of California. By agreeing to these Terms of Service, you represent that you
            are at least 21 years old, and that your use of the Site or Services does not violate
            any applicable law or regulation. Any use of the Site or Services by persons under the
            age of 21 is strictly prohibited.
          </p>
          <h2>SECTION 4 – CONTENT</h2>
          <p>
            By using this Site or Services you acknowledge and agree that the content on the Site (“
            <b>Content</b>”), and the trademarks, and all logos contained therein are owned by or
            licensed by VB Brands and are subject to copyright and other intellectual property
            rights under applicable laws. Content includes, without limitation, all source code,
            databases, functionality, software, mobile applications, website designs, audio, video,
            text, photographs, and graphics. All graphics, logos, product names, designs, page
            headers, button icons, scripts and service names are registered trademarks, common law
            trademarks or trade dress of ours. Our trademarks and trade dress may not be used,
            including as part of trademarks and/or as part of domain names, in connection with any
            product or service in any manner that is likely to cause confusion and may not be
            copied, imitated, or used, in whole or in part, without our prior written permission.
          </p>
          <p>
            Content on the Site is provided to you “AS IS” for your information and personal use
            only and may not be used, copied, reproduced, aggregated, distributed, transmitted,
            broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes
            whatsoever without the prior written consent of the respective owners. Provided that you
            are eligible to use the Site, you are granted a limited license to access and use the
            Site and the Content and to download or print a copy of any portion of the Content to
            which you have properly gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to the Site and Content.
          </p>
          <p>
            We are not responsible if Content is not accurate, complete, or current. Products shown
            on this Site may appear differently in the retail dispensary location. The Content is
            provided for general information only and should not be relied upon or used as the sole
            basis for making decisions without consulting primary, more accurate, more complete, or
            more timely sources of information.
          </p>
          <p>
            Any reliance on Content is at your own risk. We reserve the right to modify the Content
            at any time, but we have no obligation to update any information on our Site.
          </p>
          <h2>SECTION 5 – THIRD-PARTY LINKS</h2>
          <p>
            Certain Content and Services available via our Site may include materials from third
            parties. Third-party links on this site may direct you to third-party websites that are
            not affiliated with us. We are not responsible for examining or evaluating the content
            or accuracy and we do not warrant and will not have any liability or responsibility for
            any third-party materials or websites, or for any other materials, products, or services
            of third parties.
          </p>
          <p>
            We are not liable for any harm or damages related to the purchase or use of goods,
            services, resources, content, or any other transactions made in connection with any
            third-party websites. Please review carefully the third-party’s policies and practices
            and make sure you understand them before you engage in any transaction. Complaints,
            claims, concerns, or questions regarding third-party products should be directed to the
            third-party.
          </p>
          <h2>SECTION 6 – USER CONTENT</h2>
          <p>
            You grant us a license to use the materials you post to the Site and/or in connection
            with the Services. By reviewing, posting, downloading, displaying, performing,
            transmitting, or otherwise distributing information or other content, including content
            in your User Account (“<b>User Content</b>”), to the Site or in connection with the
            Service, you are granting us, our affiliates, subsidiaries, parents, officers,
            directors, employees, consultants, agents, and representatives a license to use User
            Content in connection with the operation of the Site, including without limitation, a
            right to copy, distribute, transmit, publicly display, publicly perform, reproduce,
            edit, translate, and reformat User Content; and you agree that we may publish or
            otherwise disclose your User Content in our sole and absolute discretion. However, all
            actions taken in connection with your User Content will be subject to the limitations
            set forth in our &nbsp;
            <Link style={{ textDecoration: 'underline' }} to="/privacy">
              Privacy Policy
            </Link>
            &nbsp;.
          </p>
          <p>
            You will not be compensated for any User Content. By posting User Content on the Site or
            Service, you warrant and represent that you own the rights to the User Content or are
            otherwise authorized to post, distribute, display, perform, transmit, or otherwise
            distribute User Content.
          </p>
          <p>
            You agree that your User Content will not contain libelous or otherwise unlawful,
            abusive, or obscene material, or contain any computer virus or other malware that could
            in any way affect the operation of the Site. You may not use a false e-mail address,
            pretend to be someone other than yourself, or otherwise mislead us or third parties as
            to the origin of any User Content. You are solely responsible for any User Content you
            provide and its accuracy. We take no responsibility and assume no liability for any User
            Content posted by you or any third party.
          </p>
          <p>
            We may, but have no obligation to, monitor, edit or remove User Content that we
            determine in our sole discretion to be unlawful, offensive, threatening, libelous,
            defamatory, pornographic, obscene or otherwise objectionable or in violation of any
            party’s intellectual property or these Terms of Service
          </p>
          <p>
            User Content includes, but is not limited to, Product Usage Data (as such term is
            defined in the Privacy Policy), which includes any ratings or reviews of products that
            users of the Site, including you, post when using the Site. In order to post such
            ratings or reviews, you must have a valid User Account, and you may be asked to confirm
            the validity of the email address associated with your User Account. By creating a User
            Account, you agree not to post ratings or reviews on the Site that are not based upon
            your own personal experience or that serve any purpose other than providing you or other
            users of the Site with an accurate account of your personal experience with such product
            or products. Ratings or reviews not based on personal knowledge may not be posted on the
            Site.
          </p>
          <h2>SECTION 7 – PUSH NOTIFICATIONS, TEXT MESSAGES, AND EMAILS</h2>
          <p>
            If you create a User Account and provide us with your cell phone number, you agree and
            consent to receive certain text messages from us regarding the Site and Services. These
            text messages may relate to the Site’s operations or include promotional messages.
            Standard text messaging rates will be applied by your mobile device carrier to any text
            messages sent by us. You will have the option to opt out of receiving text messages by
            replying “STOP” to any text message sent by us. After requesting to opt out, you may
            receive text messages from us for just a brief time while your request is processed.
          </p>
          <p>
            Company text messages may be generated by automatic telephone dialing systems and you
            hereby waive your right to pursue any claims (including any claim that arises while your
            request to opt out is pending) under the Telephone Consumer Protection Act (“TCPA”). To
            the extent any claim under the TCPA cannot be waived, by using the Site or Services, you
            are agreeing that any claim against Company that cannot be waived, but which arises
            under the TCPA (including any claim that arises while your request to opt out is
            pending), will be arbitrated on an individual, and not on a class or representative,
            basis, in accordance with the Governing Law and Arbitration provisions of these Terms of
            Service.
          </p>
          <p>
            By providing us with your email address, you agree that we may send you emails
            concerning our Site and Services, as well information related to third parties. You will
            have the option to opt out of such emails by following instructions to unsubscribe,
            which will be included in each email.
          </p>
          <h2>SECTION 8 – MODIFICATIONS </h2>
          <p>
            We reserve the right at any time to modify or discontinue the Site, the Services (or any
            part or Content thereof) without notice at any time. We shall not be liable to you or to
            any third party for any modification, change, suspension or discontinuance of the Site
            or Service. Company does not have any obligation under these Terms of Service, excepted
            as otherwise expressly stated, to provide you with any support or maintenance in
            connection with the Site or Services.
          </p>
          <h2>SECTION 9 – PERSONAL INFORMATION</h2>
          <p>
            We care about the privacy of our users and take our duty to protect information about
            our users seriously. Please review our &nbsp;
            <Link style={{ textDecoration: 'underline' }} to="/privacy">
              Privacy Policy
            </Link>
            &nbsp;. Our privacy policy is expressly incorporated into these Terms of Service by this
            reference.
          </p>
          <h2>SECTION 10 – ERRORS, INACCURACIES AND OMISSIONS</h2>
          <p>
            Occasionally there may be information on our Site or in the Services that contains
            typographical errors, inaccuracies or omissions that may relate to product descriptions,
            pricing, promotions, offers, and availability. We reserve the right to correct any
            errors, inaccuracies or omissions, and to change or update information in the Site or
            Service or on any related website is inaccurate at any time without prior notice.
          </p>
          <p>
            We undertake no obligation to update, amend or clarify information in the Site or
            Services or on any related website, except as required by law. No specified update or
            refresh date applied in the Site or Service or on any related website, should be taken
            to indicate that all information in the Site or Services or on any related website has
            been modified or updated.
          </p>
          <h2>SECTION 11 – PROHIBITED USES</h2>
          <p>
            In addition to other prohibitions as set forth in the Terms of Service, and excepting
            federal laws that relate to cannabis, you are prohibited from using the Site or its
            Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate
            in any unlawful acts; (c) to violate any international, federal, provincial or state
            regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our
            intellectual property rights or the intellectual property rights of others; (e) to
            harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate
            based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or
            disability; (f) to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used in any way that
            will affect the functionality or operation of the Service or of any related website,
            other websites, or the Internet; (h) to collect or track the personal information of
            others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; or (k) to interfere with or circumvent the security features
            of the Service or any related website, other websites, or the Internet. We reserve the
            right to terminate your use of the Services or any related website for violating any of
            the prohibited uses.
          </p>
          <h2>SECTION 12 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
          <p>
            YOU AGREE THAT YOUR USE OF THE SITE AND SERVICES WILL BE AT YOUR SOLE RISK. TO THE
            FULLEST EXTENT PERMITTED BY LAW. COMPANY, ITS AFFILIATES, ITS OFFICERS, DIRECTORS,
            EMPLOYEES, AND AGENTS, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
            THE SITE AND THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT.
          </p>
          <p>
            COMPANY DOES NOT WARRANT THAT YOUR USE OF THIS SITE WILL BE UNINTERRUPTED OR ERROR FREE,
            OR THAT THIS SITE OR ITS SERVER ARE FREE OF VIRUSES OR OTHER HARMFUL ELEMENTS. ALTHOUGH
            COMPANY ENDEAVORS TO PROVIDE ACCURATE INFORMATION, IT DOES NOT WARRANT OR MAKE ANY
            REPRESENTATIONS REGARDING THE ACCURACY OR RELIABILITY OF INFORMATION ON THIS SITE. YOUR
            USE OF THE SITE IS AT YOUR OWN RISK. NEITHER COMPANY NOR ITS AFFILIATED OR RELATED
            ENTITIES OR ITS VENDORS OR CONTENT PROVIDERS SHALL BE LIABLE TO ANY PERSON OR ENTITY FOR
            ANY DIRECT OR INDIRECT LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE, SPECIAL OR
            OTHERWISE), INJURY, CLAIM, OR LIABILITY OF ANY KIND OR CHARACTER WHATSOEVER BASED UPON
            OR RESULTING FROM YOUR USE OR INABILITY TO USE THIS SITE, OR ANY INFORMATION OR
            MATERIALS PROVIDED ON THE SITE. COMPANY IS NOT LIABLE FOR ANY DEFAMATORY, OFFENSIVE OR
            ILLEGAL CONDUCT OF ANY USER. IF YOU ARE DISSATISFIED WITH THE SITE OR ANY MATERIALS ON
            THE SITE, OR WITH ANY OF THE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
            DISCONTINUE USING THE SITE.
          </p>
          <p>
            COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
            OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE OR ANY HYPERLINKED
            WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND COMPANY WILL NOT BE A PARTY
            TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
            SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
            EXERCISE CAUTION WHERE APPROPRIATE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
            HEREIN, COMPANY AND ITS AFFILIATES’ LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND
            REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (A)
            THE AMOUNT PAID, IF ANY, BY YOU TO COMPANY FOR THE SERVICES DURING THE PERIOD OF ONE (1)
            MONTH PRIOR TO ANY CAUSE OF ACTION ARISING, AND (B) FIVE DOLLARS ($5). BECAUSE SOME
            STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY
            SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
          </p>
          <h2>SECTION 13 – INDEMNIFICATION</h2>
          <p>
            YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS COMPANY, ITS PARENT, SUBSIDIARIES,
            AFFILIATES, PARTNERS, SHAREHOLDERS, MEMBERS, OFFICERS, DIRECTORS, EMPLOYEES, INTERNS,
            AGENTS, DISTRIBUTORS, AND VENDORS FROM AND AGAINST ANY AND ALL CLAIMS, DEMANDS,
            LIABILITIES, COSTS OR EXPENSES, INCLUDING REASONABLE ATTORNEYS&apos; FEES AND EXPENSES,
            RESULTING OR ARISING OUT OF YOUR BREACH OF ANY OF THESE TERMS OF SERVICE OR THE
            DOCUMENTS THEY INCORPORATE BY REFERENCE, OR YOUR VIOLATION OF ANY LAW OR THE RIGHTS OF A
            THIRD PARTY. NOTWITHSTANDING THE FOREGOING, COMPANY RESERVES THE RIGHT, AT YOUR EXPENSE,
            TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER FOR WHICH YOU ARE REQUIRED TO
            INDEMNIFY COMPANY, AND YOU AGREE TO COOPERATE, AT YOUR EXPENSE, WITH COMPANY’S DEFENSE
            OF SUCH CLAIMS. COMPANY WILL USE REASONABLE EFFORTS TO NOTIFY YOU OF ANY SUCH CLAIM,
            ACTION, OR PROCEEDING WHICH IS SUBJECT TO THIS INDEMNIFICATION UPON BECOMING AWARE OF
            IT.
          </p>
          <h2>SECTION 14 - GOVERNING LAW</h2>
          <p>
            These Terms of Service and any separate agreements whereby we provide you Services shall
            be governed by and construed in accordance with the laws of the state of California,
            exclusive of conflict or choice of law rules.
          </p>
          <h2>SECTION 15 – ARBITRATION</h2>
          <p>
            In the event of any dispute with Company, you agree to first contact Company to attempt
            in good faith to resolve the dispute. All offers, promises, conduct and statements,
            whether oral or written, made in the course of negotiation to resolve the dispute by any
            of the parties, their agents, employees, experts and attorneys are confidential,
            privileged and inadmissible for any purpose, including impeachment, in arbitration or
            other proceeding involving the parties, provided that evidence that is otherwise
            admissible or discoverable shall not be rendered inadmissible or non-discoverable as a
            result of its use in the negotiation.
          </p>
          <p>
            If the dispute has not been resolved after sixty (60) days, we each agree to resolve any
            claim, dispute, or controversy (except for disputes brought in small claims court)
            arising out of or in connection with or in any way relating to these Terms of Service,
            the Site or Services, including the determination of the scope or applicability of this
            agreement to arbitrate, or the alleged breach thereof,{' '}
            <b>by binding arbitration, rather than in court,</b> in Los Angeles, California before
            one arbitrator. The arbitration shall be administered by JAMS pursuant to its
            Comprehensive Arbitration Rules and Procedures, and in accordance with the Expedited
            Procedures in those Rules. Judgment on the award may be entered in any court having
            jurisdiction. If this arbitration provision is found unenforceable or to not apply for a
            given dispute, then the proceeding must be brought exclusively in a court of competent
            jurisdiction in the city of Los Angeles, California. You hereby accept the exclusive
            jurisdiction of such court for this purpose.
          </p>
          <h2>SECTION 16 – CLASS ACTION WAIVER</h2>
          <p>
            ANY DISPUTE RESOLUTION PROCEEDINGS, WHETHER IN ARBITRATION OR COURT, WILL BE CONDUCTED
            ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS OR REPRESENTATIVE ACTION OR AS A NAMED OR
            UNNAMED MEMBER IN A CLASS, CONSOLIDATED, REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL
            LEGAL ACTION. YOUR ACCESS AND CONTINUED USE OF THE SITE OR SERVICES SIGNIFIES YOUR
            EXPLICIT CONSENT TO THIS WAIVER.
          </p>
          <h2>SECTION 17 – SEVERABILITY</h2>
          <p>
            In the event that any provision or part of a provision of these Terms of Service is
            determined to be unlawful, void or unenforceable, that provision or part of the
            provision is deemed severable from these Terms of Service and does not affect the
            validity and enforceability of any remaining provisions.
          </p>
          <h2>SECTION 18 – OTHER TERMS</h2>
          <p>
            These Terms of Service and any policies or operating rules posted by us on this Site or
            in respect to the Services constitutes the entire agreement and understanding between
            you and us and governs your use of the Site and Services, superseding any prior or
            contemporaneous agreements, communications and proposals, whether oral or written,
            between you and us (including, but not limited to, any prior versions of the Terms of
            Service). The failure of us to exercise or enforce any right or provision of these Terms
            of Service shall not constitute a waiver of such right or provision. Any ambiguities in
            the interpretation of these Terms of Service shall not be construed against the drafting
            party.
          </p>
          <h2>SECTION 19 – CHANGES TO TERMS OF SERVICE</h2>
          <p>
            You can review the most current version of the Terms of Service at any time at this
            page. We reserve the right, at our sole discretion, to update, change or replace any
            part of these Terms of Service by posting updates and changes to our Site. It is your
            responsibility to check our Site periodically for changes. Your continued use of or
            access to our Site or the Services following the posting of any changes to these Terms
            of Service constitutes acceptance of those changes.
          </p>
          <h2>SECTION 20 – MISCELLANEOUS</h2>
          <p>
            The section titles in these Terms of Service are for convenience only and have no legal
            or contractual effect. These Terms of Service operate to the fullest extent permissible
            by law. These Terms of Service and your User Account may not be assigned by you without
            our express written consent. Company may assign any or all of its rights and obligations
            to others at any time. Company shall not be responsible or liable for any loss, damage,
            delay or failure to act caused by any cause beyond Company’s reasonable control. There
            is no joint venture, partnership, employment or agency relationship created between you
            and Company as a result of these Terms or use of the Site and Services. Upon Company’s
            request, you will furnish Company any documentation, substantiation or releases
            necessary to verify your compliance with these Terms of Service. You hereby waive any
            and all defenses you may have based on the electronic form of these Terms of Service and
            the lack of signing by the parties hereto to execute these Terms of Service.
          </p>
          <h2>SECTION 21 – CONTACT INFORMATION</h2>
          <p>
            If you have any questions regarding these Terms of Service or the Privacy Policy, please
            contact:
          </p>
          <p>Address: 520 Broadway, Santa Monica CA 90401</p>
          <p>Phone: (888) 221-2554</p>
          <p>Email: hello@clickspray.com</p>
        </div>
      </Layout>
    );
  }
}
